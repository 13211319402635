import React from 'react'
import Layout from "../components/layout"
import SEO from "../components/seo"
import { Section, BreadCrumb, MarkdownContent, MarkdownContentWrapper, MarkdownContentWrapperInner, MarkdownContentTitle } from '../components/Section'
import { Link } from "gatsby"
import styled from 'styled-components'
import BgImage from '../images/brand-detail.jpg'
import { StaticImage } from "gatsby-plugin-image"
import BreakpointDown from '../components/Media/BreakpointDown'
import BreakpointUp from '../components/Media/BreakpointUp'


const SectionBorder = styled(Section)`
 border-top:1px solid #CCCCCC;
`
const SectionBgAfter = styled(Section)`
 &:after{
   right:0;
   left:0;
   background-size:cover;
   ${BreakpointDown.xl`
     opacity:0.4;
   `}
 }
 .container{
   z-index:1;
 }
`
const PageTitle = styled.h1`
 margin-bottom:20px;
 text-transform: capitalize;
`

const CardImg = styled.div`
  position: relative;
  max-width:400px;
  margin-left:auto;
  margin-right:auto;
  width:100%;
  margin-top:26px;
 ${BreakpointDown.lg`
  margin-top:0;
  margin-bottom:20px;
 `}
  ${BreakpointDown.md`
   max-width:220px;
   margin-top:0;
  `}
  .gatsby-image-wrapper{
    width:100%;
  }
`

const TraingleShape = styled.div`
  width: 0;
  height: 0;
  display: block;
  border-top: 20px transparent solid;
  border-right: 20px transparent solid;
  border-left: 20px transparent solid;
  border-bottom: 20px #fff solid;
  position: absolute;
  left: 0;
  right: 0;
  bottom: -1px;
  margin-left: auto;
  margin-right: auto;
  z-index: 3; 
`

const ProductRow = styled.div`
 display:flex;
 margin-left:-15px;
 margin-right:-15px;
 margin-top:50px;
 flex-wrap:wrap; 
 align-items: center;
 ${BreakpointDown.xl`
    flex-direction: column-reverse;
 `}
 ${BreakpointDown.md`
   margin-top:20px;
 `}
`
const ProductColumn = styled.div`
 padding-left:15px;
 padding-right:15px; 
&:first-child{ 
  flex: 0 0 50%;
  width:50%;
  font-size:16px;
  ${BreakpointDown.xl`
      flex: 0 0 100%; 
      width:100%;
  `}
}
&:last-child{
  flex: 0 0 50%;
  width:50%;
  ${BreakpointDown.xl`
      flex: 0 0 100%;
      width:100%; 
  `}
}
`
const FeatureskeyRow = styled.div`
 display:flex;
 margin-left:-15px;
 margin-right:-15px;
 flex-wrap:wrap;
 justify-content:space-between;
`
const FeatureskeyColumn = styled.div`
  padding-left:15px;
  padding-right:15px;
  flex: 0 0 45%;
  width:45%;
  margin-top:60px;
  ${BreakpointDown.lg`
      flex: 0 0 100%;
      width:100%;
      margin-top:10px;
  `}

`
const ProductColumnItem = styled.div`
width:100%;
font-size:16px;
`
const FeatureskeyTitle = styled.div`
  text-align:center;
`
const FeatureskeyColumnTitle = styled.div`
${BreakpointDown.lg`
 margin-bottom:0;
`}
 
`
const FeaturedList = styled.div`
${BreakpointUp.lg` 
  margin-left:8%; 
`}
 ul{
   margin-top:40px;
   display:flex;
   flex-wrap:wrap;
   li{
     flex:0 0 33.33%;
     width:33.33%;
     padding-left:35px;
     padding-right:35px;
     @media(max-width:1600px){
      flex:0 0 50%;
      width:50%;
     }
     ${BreakpointDown.md`
        flex:0 0 100%;
        width:100%; 
     `}
   } 
  }
`
function BrandDetailPage() {
  return (
    <Layout pageName="brand-detail">
      <SEO title="Brand Detail" />
      <SectionBgAfter pt="156px" pb="100px" xpt="90px" xpb="60px" mpb="40px" bgAfter={`url(${BgImage})`}>
        <TraingleShape />
        <div className="container">
          <BreadCrumb>
            <Link to="/">Home</Link> <Link to="/#">Zultys</Link>
          </BreadCrumb>
          <ProductRow>
            <ProductColumn>
              <PageTitle>ZIP 47G Advanced Color Gigabit IP Business Phone</PageTitle>
              <p>The ZIP 47G combines a hi-resolution color display and dual Gigabit Ethernet ports, in a feature rich IP phone ideal for busy executives and heavy phone users.</p><p>Functions and contacts may be accessed quickly via 27 programmable soft keys. A dedicated headset port and Electronic Hook Switch support offer enhanced usability for wired and wireless headset users. The optional ZIP 440M Expansion Module supports additional programmable keys.</p>
              <p>The ZIP 47G is fully compatible with Zultys’ MXIE and ZAC Unified Communication applications, allowing users to manage calls and messages directly from their computer.</p><p>Like all Zultys phones, the ZIP 47G utilizes the Session Initiation Protocol (SIP) open standard and is managed from the Zultys MX Administrator application for rapid deployment and the industry’s lowest total cost of ownership.</p>
            </ProductColumn>
            <ProductColumn>
              <CardImg>
                <StaticImage
                  src="../images/phone-reciever.png"
                  quality={100}
                  formats={["AUTO", "WEBP", "AVIF"]}
                  alt="Letast Article"
                  placeholder="blurred"
                />
              </CardImg>
            </ProductColumn>
          </ProductRow>
        </div>
      </SectionBgAfter>
      <Section pt="90px" pb="90px" xpt="60px" xpb="60px" mpt="40px" mpb="40px">
        <div className="container">
          <FeatureskeyTitle className="h2">Key Features & Benefits</FeatureskeyTitle>
          <FeatureskeyRow>
            <FeatureskeyColumn>
              <ProductColumnItem>
                <FeatureskeyColumnTitle className="h4">Heading text here</FeatureskeyColumnTitle>
                <p>Lorem Ipsum is simply dummy text of the printing and type setting industry lorem Ipsum has been industry’s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged lorem Ipsum is simplydummy text of the printing type setting industry lorem Ipsum has been industry’s standard dummy.</p>
              </ProductColumnItem>
            </FeatureskeyColumn>
            <FeatureskeyColumn>
              <ProductColumnItem>
                <FeatureskeyColumnTitle className="h4">Heading text here</FeatureskeyColumnTitle>
                <p>Lorem Ipsum is simply dummy text of the printing and type setting industry lorem Ipsum has been industry’s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged lorem Ipsum is simplydummy text of the printing type setting industry lorem Ipsum has been industry’s standard dummy.</p>
              </ProductColumnItem>
            </FeatureskeyColumn>
            <FeatureskeyColumn>
              <ProductColumnItem>
                <FeatureskeyColumnTitle className="h4">Heading text here</FeatureskeyColumnTitle>
                <p>Lorem Ipsum is simply dummy text of the printing and type setting industry lorem Ipsum has been industry’s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged lorem Ipsum is simplydummy text of the printing type setting industry lorem Ipsum has been industry’s standard dummy.</p>
              </ProductColumnItem>
            </FeatureskeyColumn>
            <FeatureskeyColumn>
              <ProductColumnItem>
                <FeatureskeyColumnTitle className="h4">Heading text here</FeatureskeyColumnTitle>
                <p>Lorem Ipsum is simply dummy text of the printing and type setting industry lorem Ipsum has been industry’s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged lorem Ipsum is simplydummy text of the printing type setting industry lorem Ipsum has been industry’s standard dummy.</p>
              </ProductColumnItem>
            </FeatureskeyColumn>
          </FeatureskeyRow>
        </div>
      </Section>
      <SectionBorder pt="100px" pb="100px" xpt="60px" xpb="60px" mpb="40px" mpt="40px">
        <div className="container">

          <FeatureskeyTitle className="h2">Feature Highlights</FeatureskeyTitle>
          <FeaturedList>
            <ul>
              <li>480×272 pixel color display</li>
              <li>Dual Gigabit Ethernet ports</li>
              <li>Full call control via ZAC/MXIE</li>
              <li>27 programmable LCD labeled keys</li>
              <li>4 context sensitive soft keys</li>
              <li>Local 3-way conference</li>
              <li>Dedicated headset port with EHS support</li>
              <li>Supports ZIP 440M LCD Expansion</li>
              <li>Busy Lamp Field (BLF)</li>
              <li>Bluetooth and Wi-Fi supported via optional USB adapters</li>
              <li>XML Browser</li>
              <li>XML and LDAP based contact directories</li>
              <li>Call forward/Call waiting</li>
              <li>One-touch call record button with look back capabilities</li>
              <li>One-touch call transfer and conference</li>
              <li>Call park and pick up</li>
              <li>Displays caller and called line info</li>
              <li>Call Log and Redial</li>
              <li>Missed call notification</li>
              <li>Paging and Intercom auto-answer</li>
              <li>VLAN, DSCP and QOS support</li>
              <li>SIP open standard</li>
              <li>MXnetwork Redundancy support</li>
              <li>802.3af Power-over-Ethernet</li>
              <li>Hearing Aid Compatible (HAC) handset</li>
              <li>Log into Call Groups from phone</li>
              <li>Wall Mountable (optional bracket required)</li>
            </ul>
          </FeaturedList>

        </div>
      </SectionBorder>

      <SectionBorder pt="100px" pb="100px" xpt="60px" xpb="60px" mpt="40px" mpb="40px">
        <div className="container">
          <MarkdownContentWrapper>
            <MarkdownContentWrapperInner>
              <MarkdownContentTitle>
                <h2 className="h1">Content Heading</h2>
              </MarkdownContentTitle>
              <MarkdownContent>
                <h3>Heading</h3>
                <p>Lorem Ipsum is simply dummy text of the printing and type setting industry lorem Ipsum has been industry’s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged lorem Ipsum is simplydummy text of the printing and type setting industry lorem Ipsum has been industry’s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled.</p>
                <ul>
                  <li>Lorem Ipsum is simply dummy text and type setting industry.</li>
                  <li>Lorem Ipsum is simply dummy text of the printing and type setting industry.</li>
                  <li>Lorem Ipsum is simply the printing and type setting industry.</li>
                  <li>Lorem Ipsum is simply dummy text and type setting industry.</li>
                  <li>Lorem Ipsum is simply the printing and type setting industry.</li>
                </ul>
              </MarkdownContent>
              <MarkdownContent>
                <h3>Heading</h3>
                <p>Lorem Ipsum is simply dummy text of the printing and type setting industry lorem Ipsum has been industry’s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged lorem Ipsum is simplydummy text of the printing and type setting industry lorem Ipsum has been industry’s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled.</p>

                <p>Lorem Ipsum is simply dummy text of the printing and type setting industry lorem Ipsum has been industry’s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged lorem Ipsum is simplydummy text of the printing and type setting industry lorem Ipsum has been industry’s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled.</p>
              </MarkdownContent>
              <MarkdownContent>
                <h3>Heading</h3>
                <p>Lorem Ipsum is simply dummy text of the printing and type setting industry lorem Ipsum has been industry’s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged lorem Ipsum is simplydummy text of the printing and type setting industry lorem Ipsum has been industry’s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled.</p>
              </MarkdownContent>
              <MarkdownContent>
                <h3>Heading</h3>
                <p>Lorem Ipsum is simply dummy text of the printing and type setting industry lorem Ipsum has been industry’s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged lorem Ipsum is simplydummy text of the printing and type setting industry lorem Ipsum has been industry’s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled.</p>
                <ul>
                  <li>Lorem Ipsum is simply dummy text and type setting industry.</li>
                  <li>Lorem Ipsum is simply dummy text of the printing and type setting industry.</li>
                  <li>Lorem Ipsum is simply the printing and type setting industry.</li>
                  <li>Lorem Ipsum is simply dummy text and type setting industry.</li>
                  <li>Lorem Ipsum is simply the printing and type setting industry.</li>
                </ul>
                <p>Lorem Ipsum is simply dummy text of the printing and type setting industry lorem Ipsum has been industry’s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged lorem Ipsum is simplydummy text of the printing and type setting industry lorem Ipsum has been industry’s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled.</p>
              </MarkdownContent>
            </MarkdownContentWrapperInner>
          </MarkdownContentWrapper>
        </div>
      </SectionBorder>
    </Layout>
  )
}
export default BrandDetailPage